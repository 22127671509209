module controllers {
    'use strict';
    export module compliance {
        interface IconsignmentComplianceListScope extends ng.IScope { IsLoading: boolean; ShowGrid: boolean;}

        interface IconsignmentComplianceListParams extends ng.ui.IStateParamsService {
            FilterList: interfaces.applicationcore.IKeyValue[];
            SearchList: interfaces.applicationcore.IKeyValue[];
        }

        export class ConsignmentComplianceListCtrl {
            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$stateParams',
                "$anchorScroll",
                "$state",
                "bsLoadingOverlayService",
                "lookupService",
                "$timeout",
                '$transitions',
                'consignmentTemplateService',
                'documentRepositoryService',
                '$uibModal',
                'userAccountService',
                "isInbound",
                "entityService",
                "consignmentService",
                "statusService",
                "classificationValueService",
                "supplierService",
                "consigneeService"];

            complianceSearch: interfaces.compliance.IComplianceSearch = {
                ComplianceStatusId: 602
            };

            breadCrumbDesc: string;

            IsInbound: boolean;
            IsLoading: boolean = false;
            showGrid: boolean = false;
            searchAccordian: boolean = false;

            supplierList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            consigneeList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            //Entity Variables
            ownerEntityId: number;
            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            dropdownsObject = {
                Entities: Array<interfaces.applicationcore.IEntity>(),
                ComplianceStatusList: Array<interfaces.applicationcore.IDropdownModel>(),
                TemplateList: Array<interfaces.applicationcore.IDropdownModel>(),
            };


            apiConList: uiGrid.IGridApi;
            selectedCon: uiGrid.IGridRow;

            sortName: string;
            sortDirection: string;

            filterNames: string[] =
            [
                "OwnerEntityCode",
                "WaybillNumber",
                "ConsignmentDate",
                "FinancialInvoiceNumber",
                "ManifestNumber",
                "ConsigneeName",
                "SupplierName",
                "Status",
                "Template",
                "CountryOfLoading",
                "CountryOfUnloading",
                "ResponsibleUser"
            ];

            filterList: interfaces.applicationcore.IKeyValue[] = [];

            constructor(
                private $scope: IconsignmentComplianceListScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $stateParams: IconsignmentComplianceListParams,
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,
                public bsLoadingOverlayService,
                private lookupService: interfaces.applicationcore.ILookupService,
                public $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private consignmentTemplateService: interfaces.compliance.IConsignmentTemplateService,
                private documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                public userService: interfaces.applicationcore.IUserAccountService,
                private isInbound: boolean,
                private entityService: interfaces.applicationcore.IEntityService,
                private consignmentService: interfaces.consignment.IConsignmentService,
                private statusService: interfaces.master.IStatusService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private supplierService: interfaces.master.ISupplierService,
                private consigneeService: interfaces.master.IConsigneeService,
            ) {
                if ($stateParams.FilterList) {
                    this.complianceSearch = {};
                    this.filterList = $stateParams.FilterList;
                }

                var obj: any = {}
                if ($stateParams.SearchList) {
                    angular.forEach($stateParams.SearchList, (value, index) => {
                        obj[value.key] = value.value;
                    });
                    obj['ComplianceStatusId'] = -1;
                    this.complianceSearch = obj;
                }                               

                this.IsInbound  = isInbound;

                this.loadControls();

                $timeout(() => {
                    this.showGrid = true;
                });

            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadControls() {
                this.$scope.ShowGrid = false;

                let controlPromises = [
                    this.getCurrentEntity(),
                    this.loadEntities(),
                    this.getComplianceStatusDropdown(),
                    this.getTemplateDropdown()
                ]

                this.$q.all(controlPromises).then((data) => {
                    this.$scope.ShowGrid = true;
                });
            }

            //Loading Entities
            loadEntities(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getList().query((successList) => {
                    this.dropdownsObject.Entities = successList;
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            getCountryLoadingList() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.consignmentService.getCountryLoadingList().query({}, (result) => {
                    defered.resolve(result);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            getComplianceStatusDropdown() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: 133
                }, (resultList) => {
                    this.dropdownsObject.ComplianceStatusList = resultList;
                }).$promise;
            }


            getTemplateDropdown() {
                return this.consignmentTemplateService.getForTemplateDropdown(this.isInbound).query({
                }, (resultList) => {
                    this.dropdownsObject.TemplateList = resultList;
                }).$promise;
            }

            loadSuppliers(searchText: string) {
                return this.supplierService.getSupplierDropdown(this.ownerEntityId, searchText).query({
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            //Loading the Consignees
            loadConsignees(searchText: string) {
                return this.consigneeService.getDropdownList(this.ownerEntityId, searchText).query({
                }, () => {

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            showAll_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;
                this.complianceSearch = {
                    ComplianceStatusId: 602
                }

                if (this.apiConList !== undefined) {
                    this.apiConList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiConList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            search_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;

                this.DoSearch();
            }

            close() {
                this.$state.go("^");
            }

            downloadToExcel() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.complianceSearch, (n, key) => {
                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiConList) {
                    var grid = this.apiConList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                var params = {
                    connectSearch: searchObject,
                    isInbound: this.isInbound,
                }

                this.IsLoading = true;
                this.consignmentTemplateService.getComplianceExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwConList.data = [];
                    this.gvwConList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            DoSearch() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.complianceSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiConList) {
                    var grid = this.apiConList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }
                
                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber,
                    isInbound: this.isInbound
                }

                this.IsLoading = true;

                this.consignmentTemplateService.getComplianceList().query(params, (data) => {
                    this.IsLoading = false;
                    this.gvwConList.data = data;

                    if (data[0]) {
                        this.gvwConList.totalItems = data[0].NumRecords;
                    } else {
                        this.gvwConList.totalItems = 0;
                    }

                    this.$timeout(() => {
                        if (this.selectedCon) {
                            var index = _.findIndex(this.gvwConList.data, (o) => { return o.Id === this.selectedCon.entity.Id });
                            this.apiConList.selection.selectRow(this.gvwConList.data[index]);
                        }
                        this.getFilters();
                        this.setFilters();
                    });

                }, (data) => {
                    this.IsLoading = false;
                    this.gvwConList.data = [];
                    this.gvwConList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }


            showAll() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;

                this.DoSearch();
            }

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiConList.grid.getColumn(name).filters[0].term || this.apiConList.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiConList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiConList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiConList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    var column = this.apiConList.grid.getColumn(this.sortName);

                    if (column.sort.direction != this.sortDirection) {
                        this.apiConList.grid.sortColumn(column, this.sortDirection);
                    }
                }
            }


        PerformEndoftheDayTaskClick() {
                this.$rootScope.Messages = [];
                var HasErrors = false;

                if (!HasErrors) {
                    this.generalService.displayConfirmationBox("Confirm?", "Perform End of Day Tasks?").then((result: boolean) => {
                        if (result) {

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'compliance.view'
                            }, () => {
                                return this.PerformEndoftheDayTask();
                            });
                        }
                    });
                }

            }

            PerformEndoftheDayTask() {
                return this.consignmentTemplateService.performEndoftheDayTask().save(
                    { },
                    (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);                        
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.showAll();
                this.apiConList = gridApi;

                this.apiConList.core.on.filterChanged(this.$scope, () => { this.ConListFilterChange(gridApi) });
                this.apiConList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ConListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiConList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ConListPageChange(newPage, pageSize) });

                this.apiConList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.$timeout(() => {
                        this.selectedCon = selected;
                    });
                });

                this.$timeout(() => {
                    if (this.selectedCon)
                        this.apiConList.selection.selectRow(this.selectedCon.entity);
                });
            }

            ConListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ConListShortChange(gridApi) {
                this.DoSearch();
            }

            ConListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if(!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if(keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });
                
                this.DoSearch();
            }   

            editCompliance(transactionId: number) {
                if (this.isInbound) {
                    this.$state.go("auth.ComplianceImports.Update", { transactionId: transactionId });
                } else {
                    this.$state.go("auth.ComplianceExports.Update", { transactionId: transactionId });
                }
            };

            gvwConList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi);


                    this.apiConList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiConList.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, 'SLA-amber': row.entity.SLAStatus === 1, 'SLA-danger': row.entity.SLAStatus === 2 }" ui-grid-cell></div>`,
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                        <div> 
                            <button type="button" ng-click="grid.appScope.consignmentComplianceListCtrl.editCompliance(row.entity.Id)" class="btn btn-default btn-sm">
                                <span class="fa fa-pencil"></span>
                            </button>
                        </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, 
                {
                    name: "OwnerEntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 60,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                },
                {
                    name: "WaybillNumber",
                    displayName: "Consignment",
                    field: "ConsignmentNumber",
                    width: 100,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                },
                {
                    name: "ConsignmentDate",
                    displayName: "Consignment Date",
                    field: "ConsignmentDate",
                    width: 135,
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="waybillDate"></gts-date>
                        </div>`,
                },
                {
                    name: "FinancialInvoiceNumber",
                    displayName: "Financial Invoice Number",
                    field: "FinancialInvoiceNumber",
                    width: 120,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "ManifestNumber",
                    displayName: "Manifest",
                    field: "ManifestNumber",
                    width: 80,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                },
                {
                    name: "ConsigneeName",
                    displayName: "Consignee",
                    field: "ConsigneeName",
                    width: 140,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                },
                {
                    name: "SupplierName",
                    displayName: "Supplier",
                    field: "SupplierName",
                    width: 200,
                    visible: this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                },
                {
                    name: "Status",
                    displayName: "Status",
                    field: "Status",
                    width: 140,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                },
                {
                    name: "PercentageComplete",
                    enableFiltering: false,
                    displayName: "Percentage Complete",
                    field: "PercentageComplete",
                    width: 100
                },
                {
                    name: "ResolutionDate",
                    displayName: "Resolution Date",
                    field: "ResolutionDate",
                    width: 135,
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="resolutionDate"></gts-date>
                        </div>`,
                },
                {
                    name: "Template",
                    displayName: "Template",
                    field: "TemplateDescription",
                    width: 130,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>'
                },
                {
                    name: "CountryOfLoading",
                    displayName: "Origin",
                    field: "CountryOfOriginCode",
                    width: 100,
                    visible: this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "CountryOfUnloading",
                    displayName: "Destination",
                    field: "CountryOfDestinationCode",
                    width: 60,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "ResponsibleUser",
                    displayName: "Responsible User",
                    field: "User",
                    width: 110,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }]
            };
        }

        angular.module("app").controller("consignmentComplianceListCtrl", controllers.compliance.ConsignmentComplianceListCtrl);
    }
}